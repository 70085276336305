import Vue from 'vue'
import Vuex from 'vuex'
import {ulid} from 'ulid';

Vue.use(Vuex);

export function createApplicationStore() {

  const store = new Vuex.Store({
    state: {
      userId: null,
      username: null,
    },
    getters: {
      isLoggedIn(state) {
        return null !== state.userId;
      },
    },
    mutations: {
      SET_USER_ID(state, userId) {
        state.userId = userId;
        localStorage.setItem('userId', userId);
      },
      SET_USERNAME(state, username) {
        state.username = username;
        localStorage.setItem('username', username);
      },
    },
    actions: {
      async SET_USER({commit}, {username, userId = ulid()}) {
        commit('SET_USER_ID', userId);
        commit('SET_USERNAME', username);
      },
    },
  });

  const username = localStorage.getItem('username');
  const userId = localStorage.getItem('userId');

  if (null !== username && null !== userId) {
    store.commit('SET_USERNAME', username);
    store.commit('SET_USER_ID', userId);
  }

  return store;
}


