import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import {createRouter} from "@/router";
import {createApplicationStore} from "@/store";
import empty from 'is-empty';
import Clipboard from 'v-clipboard';

require('./assets/css/main.scss');

Vue.use(Clipboard);
Vue.mixin({
  methods: {
    empty
  }
});

Vue.config.productionTip = false;

const store = createApplicationStore();
const router = createRouter({store});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
