import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import empty from 'is-empty';

Vue.use(VueRouter)

export function createRouter({store}) {
  const routes = [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        authenticated: true,
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
      path: '/board/:id/play',
      name: 'board-play',
      props: true,
      component: () => import(/* webpackChunkName: "play" */ '../views/Play.vue'),
      meta: {
        authenticated: true,
      }
    },
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });


  router.beforeEach((to, from, next) => {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!empty(to.meta.authenticated) && false === store.getters.isLoggedIn) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      });
    } else {
      next();
    }
  });


  return router;
}
