<template>
  <div id="app">
    <router-view @login="login"/>
  </div>
</template>
<script>
  import empty from 'is-empty';

  export default {
    methods: {
      async login(username) {
        await this.$store.dispatch('SET_USER', {username});
        await this.$router.push(!empty(this.$route.query.redirect) ? this.$route.query.redirect : '/');
      }
    },
  }
</script>
<style lang="scss">
  body {
    padding: 0;
    margin: 0;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
</style>
