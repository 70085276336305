<template>
  <div class="home">
    Welcome, {{ username }}.

    <div class="mt-16">
      <button @click="createNewGame" class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">
          Play Otrio !
        </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {ulid} from 'ulid';

  export default {
    computed: {
      ...mapState([
        'username',
      ])
    },
    methods: {
      async createNewGame() {
        const id = ulid();
        await this.$router.push({
          name: 'board-play',
          params: {id, 'igniter': true}
        });
      },
    }
  }
</script>
